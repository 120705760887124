import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MdxLayout from 'components/mdx-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <MdxLayout title="Installation" subline="Using Backbreeze in your Sass project.">
    {children}
  </MdxLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are multiple ways to use `}<a parentName="p" {...{
        "href": "https://sass-lang.com/"
      }}>{`Sass`}</a>{` and multiple Sass versions. All testing for this library is done using `}<a parentName="p" {...{
        "href": "https://github.com/sass/node-sass"
      }}><inlineCode parentName="a">{`node-sass`}</inlineCode></a>{`. There are some recommendations you can find on the `}<a parentName="p" {...{
        "href": "https://sass-lang.com/install"
      }}>{`Sass website`}</a>{` on how to install Sass.`}</p>
    <p>{`A common way to include Sass processing in your project is to use a build tool or task runner like: `}<a parentName="p" {...{
        "href": "https://github.com/webpack-contrib/sass-loader"
      }}>{`Webpack`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/gulp-sass"
      }}>{`Gulp`}</a>{`, `}<a parentName="p" {...{
        "href": "https://parceljs.org/scss.html"
      }}>{`Parcel`}</a>{` or just npm scripts.`}</p>
    <p>{`This guide assumes that you have a running Sass setup and are using a `}<inlineCode parentName="p">{`main.scss`}</inlineCode>{` file (name is not relevant) to include all your Sass partials.`}</p>
    <p>{`NOTE: Backbreeze is not (yet) written using the new `}<a parentName="p" {...{
        "href": "https://sass-lang.com/blog/the-module-system-is-launched"
      }}>{`sass module system`}</a>{` to be more compatible with older codebases. This might change in the future.`}</p>
    <h2 {...{
      "id": "installation-via-npm"
    }}>{`Installation via NPM`}</h2>
    <p>{`Backbreeze is distributed via `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/backbreeze"
      }}>{`npm`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm install --save backbreeze
`}</code></pre>
    <p>{`Alternatively you can clone the git repository. And copy the files to some location in your project. This approach is not recommended as you will not have automatic updates and bug fixes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Clone from GitHub
$ git clone https://github.com/nicolas-cusan/backbreeze.git

# Go into directory
$ cd backbreeze

# Remove the git folder
$ rm -rf .git
`}</code></pre>
    <h2 {...{
      "id": "including-in-your-project"
    }}>{`Including in your project`}</h2>
    <p>{`Backbreeze provides two Sass partials: `}<inlineCode parentName="p">{`_config.scss`}</inlineCode>{` and `}<inlineCode parentName="p">{`_utilities.scss`}</inlineCode>{`. Due to the way default variables work in Sass you need to overwrite any Backbreeze variables `}<strong parentName="p">{`before`}</strong>{` including the Backbreeze `}<inlineCode parentName="p">{`_config.scss`}</inlineCode>{` file. It is recommended to include the `}<inlineCode parentName="p">{`_utilities.scss`}</inlineCode>{` partial last to enable overwriting of any other styles.`}</p>
    <p>{`To keep things tidy it is also recomended to have a `}<inlineCode parentName="p">{`_vars.scss`}</inlineCode>{` (name is not relevant) partial to hold your Backbreeze configuration (Learn how to configure Backbreeze `}<a parentName="p" {...{
        "href": "/configuration/"
      }}>{`here`}</a>{`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// main.scss

// Project variables and Backbreeze configuration
@include 'vars';

// Backbreeze \`_config.scss\` (file path might vary depending on your setup)
@include 'path/to/node_modules/backbreeze/src/config';

// Reset stylesheet
@include 'path/to/node_modules/destyle.css';

// Custom project styles
@include 'my-include';
@include 'my-other-include';
// ....

// Backbreeze \`_utilities.scss\` last to overwrite any other styles
// (file path might vary depending on your setup)
@include 'path/to/node_modules/backbreeze/src/utilities';
`}</code></pre>
    <h2 {...{
      "id": "other-ways-to-use-backbreeze"
    }}>{`Other ways to use Backbreeze`}</h2>
    <p>{`The Backbreeze npm package includes a compiled CSS version of the library with the default values. However as Backbreeze does not provide a large number of default values it is not recommended to use this version. In addition to that Backbreeze generates a lot of classes so file size might be an issue. It is highly recommended to remove unused classes from your code base for production, you can learn more about it `}<a parentName="p" {...{
        "href": "/using-in-production/"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      